import * as PIXI from 'pixi.js';

import { SlotId } from '../../config';
import { Events, FeatureState } from '../../global.d';

import TypedEmitter from './typedEmitter';

export * from './textStyles';
export const eventManager = new PIXI.utils.EventEmitter() as TypedEmitter<Events>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access
(window as unknown as any).eventManager = eventManager; // Trick to proxy eventManager to cheats file

export enum SlotMachineState {
  IDLE,
  SPIN,
  STOP,
  JINGLE,
  WINNING,
}
// APPLICATION SETTINGS
export const APPLICATION_TRANSPARENT = false;
export const APPLICATION_FPS = 60;

// WIN SETTINGS
// export const WIN_ANIMATION_INTERVAL = 1_000;
export const WIN_ANIMATION_DELAY_TIME_DURATION = 2_000;
export const WIN_CHECK_TIME_DURATION_ON_FREE_SPIN = 1_000;

// BASE WIN SETTINGS
export const DOUBLE_WIN_AMOUNT_LIMIT = 2;
export const BASE_WIN_AMOUNT_LIMIT = 10;
export const BASE_WIN_TITLE_SCALE = 1.2;
export const BASE_WIN_COUNT_UP_MULTIPLIER = 0.05;

// BIG WIN SETTINGS
export const BIG_WIN_TITLE = 'BIG WIN!';
export const BIG_WIN_AMOUNT_LIMIT = 25;
export const BIG_WIN_TITLE_SCALE = 1.3;
export const BIG_WIN_ZOOM_TITLE_DURATION = 500;
export const BIG_WIN_COUNT_UP_MULTIPLIER = 0.0265;
export const BIG_WIN_END_DURATION = 1000;

// MEGA WIN SETTINGS
export const MEGA_WIN_TITLE = 'MEGA WIN!';
export const MEGA_WIN_AMOUNT_LIMIT = 50;
export const MEGA_WIN_TITLE_SCALE = 1.4;
export const MEGA_WIN_ZOOM_TITLE_DURATION = 550;
export const MEGA_WIN_COUNT_UP_MULTIPLIER = 0.1;

// GREAT WIN SETTINGS
export const GREAT_WIN_TITLE = 'GREAT WIN!';
export const GREAT_WIN_AMOUNT_LIMIT = 100;
export const GREAT_WIN_TITLE_SCALE = 1.5;
export const GREAT_WIN_ZOOM_TITLE_DURATION = 600;
export const GREAT_WIN_COUNT_UP_MULTIPLIER = 0.2;

// EPIC WIN SETTINGS
export const EPIC_WIN_TITLE = 'EPIC WIN!';
export const EPIC_WIN_TITLE_SCALE = 1.6;
export const EPIC_WIN_ZOOM_TITLE_DURATION = 650;
export const EPIC_WIN_COUNT_UP_MULTIPLIER = 0.5;

// WIN SLOT ANIMATION SETTINGS
export const WIN_SLOT_ANIMATION_SCALE = 1.15;
export const WIN_SLOT_ANIMATION_DURATION = 600;
export const TURBO_SPIN_WIN_SLOT_ANIMATION_COEFFICIENT = 1;
export const WIN_SLOT_TINT_COLOR = 0x888888;
export const SPRITE_ANIMATION_FPS = 30;

// GENERAL SLOT MACHINE SETTINGS
export const REELS_AMOUNT = 6;
export const SLOTS_PER_REEL_AMOUNT = 6;
export const REEL_WIDTH = 157.5;
export const REEL_HEIGHT = 130;
export const SLOT_RESOURCE_WIDTH = 280;
export const SLOT_RESOURCE_HEIGHT = 180;
export const SLOT_WIDTH = 156;
export const SLOT_HEIGHT = 130;
export const SLOT_SCALE = 1.0;
export const SHOW_ALL_LINES_ON_WIN = true;
export const SLOTS_CONTAINER_WIDTH = REEL_WIDTH * REELS_AMOUNT;
export const SLOTS_CONTAINER_HEIGHT = SLOT_HEIGHT * SLOTS_PER_REEL_AMOUNT;
export const SHOW_LINES_NUMBER_CONTAINER = false;
export const LINES_NUMBER_CONTAINER_WIDTH = 30;
export const MINIMUM_SPIN_SLOTS_AMOUNT = 50;
export const GAME_CONTAINER_HEIGHT = SLOT_HEIGHT * SLOTS_PER_REEL_AMOUNT;
export const GAME_CONTAINER_WIDTH = SHOW_LINES_NUMBER_CONTAINER
  ? SLOTS_CONTAINER_WIDTH + 2 * LINES_NUMBER_CONTAINER_WIDTH
  : SLOTS_CONTAINER_WIDTH;
export const SLOTS_BACKGROUND_HEIGHT = SLOTS_CONTAINER_HEIGHT;
export const SLOTS_BACKGROUND_WIDTH = SLOTS_CONTAINER_WIDTH;

export const SLOTS_BASE_GAME_ADJUST = 100;

export const WIN_ANIM_Y_ADJUST = 6;
export const WIN_ANIM_X1_ADJUST = 194.5;
export const WIN_ANIM_X2_ADJUST = 601.5;
export const WIN_ANIM_X3_ADJUST = 1008.5;

// REELS FRAME SETTINGS
export const REELS_FRAME_ADJUSTMENT_POS_X = 0;
export const REELS_FRAME_ADJUSTMENT_POS_Y = 0;

// ANTICIPATION SETTINGS
export const ANTICIPATION_ENABLE = true;
export const ANTICIPATION_SYMBOLS_ID = [];
export const ANTICIPATION_START_SYMBOLS_AMOUNT = [1, 1, 1, 1, 1];
export const ANTICIPATION_END_SYMBOLS_AMOUNT = [2, 2, 2, 2, 2];
export const ANTICIPATION_SYMBOLS_AMOUNT_BASE_GAME = [2, 2];
export const ANTICIPATION_SYMBOLS_AMOUNT_FREE_SPINS_MODE = [2, 2];

export const ANTICIPATION_DELAY = 0;
export const ANTICIPATION_DURATION = 2_000;
export const ANTICIPATION_REEL_ENDING_SLOTS_AMOUNT = 15;
export const ANTICIPATION_REEL_FORMULA = (t: number): number => (t - 1) * (t - 1) * ((0.5 + 1) * (t - 1) + 0.5) + 1;
export const ANTICIPATION_SLOTS_TINT = 0xaaaaaa;

// REEL STOP SOUND SETTINGS
export const BONUS_SYMBOLS_ID = [[SlotId.WL, SlotId.WL, SlotId.WL]];

// SAFE AREA SETTINGS
export const SAFE_AREA_DESKTOP_BOTTOM_PADDING = 0;
export const SAFE_AREA_MOBILE_BOTTOM_PADDING = 0;
export const SAFE_AREA_LANDSCAPE_WIDTH_MARGIN = 550;
export const SAFE_AREA_LANDSCAPE_WIDTH = GAME_CONTAINER_WIDTH + SAFE_AREA_LANDSCAPE_WIDTH_MARGIN;
export const SAFE_AREA_LANDSCAPE_HEIGHT_MARGIN = 380; //420;
export const SAFE_AREA_LANDSCAPE_HEIGHT = GAME_CONTAINER_HEIGHT + SAFE_AREA_LANDSCAPE_HEIGHT_MARGIN;
export const SAFE_AREA_LANDSCAPE_PIVOT_X = -SAFE_AREA_LANDSCAPE_WIDTH_MARGIN / 2;
export const SAFE_AREA_LANDSCAPE_PIVOT_Y = -250; //-180;

export const SAFE_AREA_PORTRAIT_WIDTH_MARGIN = 75;
export const SAFE_AREA_PORTRAIT_WIDTH = GAME_CONTAINER_WIDTH + SAFE_AREA_PORTRAIT_WIDTH_MARGIN;
export const SAFE_AREA_PORTRAIT_HEIGHT_MARGIN = 550;
export const SAFE_AREA_PORTRAIT_HEIGHT = GAME_CONTAINER_HEIGHT + SAFE_AREA_PORTRAIT_HEIGHT_MARGIN;
export const SAFE_AREA_PORTRAIT_PIVOT_X = -SAFE_AREA_PORTRAIT_WIDTH_MARGIN / 2;
export const SAFE_AREA_PORTRAIT_PIVOT_Y = -200;

// REELS SETTINGS
export const BASE_SPIN_TIME = 2000;
export const TURBO_SPIN_TIME = 1000;
export const ADDITIONAL_SPIN_TIME_PER_REEL = 300;
export const TURBO_ADDITIONAL_SPIN_TIME_PER_REEL = 100;
export const TURBO_SPIN_REEL_ANIMATION_DELAY_PER_REEL = 25;
export const SPIN_REEL_ANIMATION_DELAY_PER_REEL = 50;
// export const PERSISTENT_SHARP_SYMBOLS_ID = [SlotId.SC1, SlotId.WL, SlotId.MS1];
export const BASE_REEL_BLUR_VALUE = 18;
export const TURBO_REEL_BLUR_VALUE = 24;
export enum ReelState {
  IDLE,
  STARTING,
  ROLLING,
  ENDING,
}

// SPIN ANIMATION SETTINGS
export const INIT_SLOTS_AMOUNT_SPIN_BEFORE_STOP = 50;
export const BASE_REEL_STARTING_DURATION = 500;
export const BASE_REEL_FIRST_ROLLING_DURATION = 500;
export const BASE_REEL_ROLLING_DURATION = 1250;
export const BASE_REEL_ROLLING_SPEED = 0.04;
export const BASE_REEL_ENDING_DURATION = 250;
export const FAKE_ROLLING_DURATION = 12_000;
export const TURBO_REEL_STARTING_DURATION = 500;
export const TURBO_REEL_FIRST_ROLLING_DURATION = 500;
export const TURBO_REEL_ROLLING_DURATION = 100;
export const TURBO_REEL_ROLLING_SPEED = 0.04;
export const TURBO_REEL_ENDING_DURATION = 250;
export const FORCE_STOP_SPIN_ANIMATION_DURATION = 250;
export const FORCE_STOP_SPIN_PER_EACH_DURATION = 50;
export const REEL_STARTING_SLOTS_AMOUNT = (BASE_REEL_ROLLING_SPEED * BASE_REEL_STARTING_DURATION) / 3.4;
export const REEL_ENDING_SLOTS_AMOUNT = (BASE_REEL_ROLLING_SPEED * BASE_REEL_ENDING_DURATION) / 2; // (speed*duration) / (df/dt)(0)
export const BASE_REEL_STARTING_FORMULA = (t: number): number => t * t * (1.4 * t - 0.4);
export const BASE_REEL_ENDING_FORMULA = (t: number): number => (t - 1) * (t - 1) * ((0.5 + 1) * (t - 1) + 0.5) + 1;

export const BASE_REEL_PHOENIX_ROLLING_DURATION = 2000;
export const BASE_REEL_PHOENIX_ROLLING_DURATION_TURBO = 3000;

// GAME TITLE LOGO
export const GAME_TITLE_LOGO_X_POSITION = 130;
export const GAME_TITLE_LOGO_Y_POSITION = -100;

// TINT CONTAINER SETTINGS
export const TINT_CONTAINER_WIDTH = REEL_WIDTH;
export const TINT_CONTAINER_HEIGHT = SLOTS_BACKGROUND_HEIGHT;
export const TINT_BACKGROUND_COLOR = 0x000000;
export const TINT_ALPHA = 0.3;

// PAY_TABLE SETTINGS
export const PAY_TABLE_WIDTH = SLOT_WIDTH;
export const PAY_TABLE_HEIGHT = SLOT_HEIGHT;
export const PAY_TABLE_BACKGROUND_COLOR = 0x000000;
export const PAY_TABLE_NUMBER_COLOR = 0xffffff;
export const PAY_TABLE_TEXT_COLOR = 0xf6e87e;
export const PAY_TABLE_TEXT_ADJUST = 40;
export const PAY_TABLE_IMG_ADJUST = 0.8;

// COINS ANIMATIONS SETTINGS
export const COINS_AMOUNT_PER_SEC = 8;
export const SINGLE_COIN_ANIMATION_DURATION = 2500;
export const MINIMUM_FRACTION_DIGITS = 2;
export const MAXIMUM_FRACTION_DIGITS = 2;

export const WIN_ANIM_START_DELAY = 500;

// SCENE CHANGE
export const SCENE_CHANGE_BACKGROUND_COLOR = 0xffffff;
export const SCENE_CHANGE_FADE_TIME = 750;
export const SCENE_CHANGE_BASE_SCREEN_ANIMATION_TIME = 750;
export const SCENE_CHANGE_LOGO_SCREEN_ANIMATION_TIME = 1000;
export const SCENE_CHANGE_LOGO_OUT_SCREEN_ANIMATION_TIME = 500;
export const SCENE_CHANGE_BLACK_SCREEN_WAIT_TIME = 1250;
export const SCENE_CHANGE_SCALE = 0.4;
export const SCENE_CHANGE_NINJA_LANDING_PLACE = 440;

// FREE SPINS
export const FREE_SPINS_FADE_OUT_DURATION = 1000;
export const FREE_SPINS_FADE_IN_DURATION = 1000;
// export const FREE_SPINS_TIME_OUT_BANNER = 3500;

export const WIN_CHECK_TIME_DURATION_ON_FREESPIN = 1_000;
export const BIG_WIN_CHECK_TIME_DURATION_ON_FREESPIN = 3_000;

export const BB_BANNER_FADEOUT_TIME = 1000;

export const BGM_FADE_OUT_TIME = 100;
export const BGM_FADE_OUT_VOLUME = 0;
export const BGM_FADE_IN_ADJUSTMENT = 400;
export const BGM_FADE_IN_TIME = 100;

export const FREE_SPINS_FADE_DURATION = 4000;
export const FREE_SPINS_TIME_OUT_RETRIGGER_BANNER = 5000;
export const FREE_SPINS_LVL_UP_CHANGES_DELAY = 500;
export const FREE_SPINS_LVL_MAX_CHANGES_DELAY = 150;
export const RETRIGGER_BANNER_VISIBLE_DELAY = 750;
export const FREE_SPINS_TIME_OUT_BANNER = 1000;
export const RETRIGGER_ANIMATION_DELAY = 1500;
export const RETRIGGER_MESSAGE_DELAY = 500;

// SPINE FULL SCREEN SIZE
export const LANDSCAPE_FULL_SCREEN_WIDTH = 1920;
export const LANDSCAPE_FULL_SCREEN_HEIGHT = 1080;
export const PORTRAIT_FULL_SCREEN_WIDTH = 414;
export const PORTRAIT_FULL_SCREEN_HEIGHT = 736;

// FREE SPINS DISPLAY
export const FREE_SPIN_COUNTER_X = 370;
export const FREE_SPIN_COUNTER_Y = -40;
export const FREE_SPIN_COUNTER_TITLE_X = 280;
export const FREE_SPIN_COUNTER_TITLE_Y = 215;
export const FREE_SPIN_COUNTER_SPIN_X = 510;
export const FREE_SPIN_COUNTER_SPIN_Y = FREE_SPIN_COUNTER_TITLE_Y;
export const FREE_SPIN_COUNTER_SP_SYMBOL_X = -40;
export const FREE_SPIN_COUNTER_SP_SYMBOL_Y = 150;
export const FREE_SPIN_COUNTER_FREE_SPIN_X = -80;
export const FREE_SPIN_COUNTER_FREE_SPIN_Y = 175;

// WIN STAGES
export enum WinStages {
  None,
  BaseWin,
  BigWin,
  MegaWin,
  GreatWin,
  EpicWin,
}

// LINES SETTINGS
type Position = {
  [key: number]: {
    x: number;
    y: number;
  };
};
export const POSITION: Position = {
  0: {
    x: REEL_WIDTH / 2,
    y: SLOT_HEIGHT / 2,
  },
  1: {
    x: REEL_WIDTH + REEL_WIDTH / 2,
    y: SLOT_HEIGHT / 2,
  },
  2: {
    x: REEL_WIDTH * 2 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT / 2,
  },
  3: {
    x: REEL_WIDTH * 3 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT / 2,
  },
  4: {
    x: REEL_WIDTH * 4 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT / 2,
  },
  5: {
    x: REEL_WIDTH / 2,
    y: SLOT_HEIGHT + SLOT_HEIGHT / 2,
  },
  6: {
    x: REEL_WIDTH + REEL_WIDTH / 2,
    y: SLOT_HEIGHT + SLOT_HEIGHT / 2,
  },
  7: {
    x: REEL_WIDTH * 2 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT + SLOT_HEIGHT / 2,
  },
  8: {
    x: REEL_WIDTH * 3 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT + SLOT_HEIGHT / 2,
  },
  9: {
    x: REEL_WIDTH * 4 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT + SLOT_HEIGHT / 2,
  },
  10: {
    x: REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 2 + SLOT_HEIGHT / 2,
  },
  11: {
    x: REEL_WIDTH + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 2 + SLOT_HEIGHT / 2,
  },
  12: {
    x: REEL_WIDTH * 2 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 2 + SLOT_HEIGHT / 2,
  },
  13: {
    x: REEL_WIDTH * 3 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 2 + SLOT_HEIGHT / 2,
  },
  14: {
    x: REEL_WIDTH * 4 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 2 + SLOT_HEIGHT / 2,
  },
  15: {
    x: REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 3 + SLOT_HEIGHT / 2,
  },
  16: {
    x: REEL_WIDTH + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 3 + SLOT_HEIGHT / 2,
  },
  17: {
    x: REEL_WIDTH * 2 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 3 + SLOT_HEIGHT / 2,
  },
  18: {
    x: REEL_WIDTH * 3 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 3 + SLOT_HEIGHT / 2,
  },
  19: {
    x: REEL_WIDTH * 4 + REEL_WIDTH / 2,
    y: SLOT_HEIGHT * 3 + SLOT_HEIGHT / 2,
  },
};

// LINE NUMBERS CONTAINER SETTINGS
export const LINES_NUMBER_CONTAINER_HEIGHT = SLOTS_CONTAINER_HEIGHT;
export const LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X = 5;
export const RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X = 0;
export const LEFT_LINES_NUMBERS_CONTAINER_POSITIONS = [
  { id: 0, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 0 },
  { id: 1, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 35 },
  { id: 2, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 70 },
  { id: 3, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 105 },
  { id: 4, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 140 },
  { id: 5, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 175 },
  { id: 6, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 210 },
  { id: 7, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 245 },
  { id: 8, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 280 },
  { id: 9, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 315 },
  { id: 10, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 350 },
  { id: 11, x: LEFT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 385 },
];
export const RIGHT_LINES_NUMBERS_CONTAINER = [
  { id: 12, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 0 },
  { id: 13, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 32 },
  { id: 14, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 64 },
  { id: 15, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 96 },
  { id: 16, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 128 },
  { id: 17, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 160 },
  { id: 18, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 192 },
  { id: 19, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 224 },
  { id: 20, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 256 },
  { id: 21, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 288 },
  { id: 22, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 320 },
  { id: 23, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 352 },
  { id: 24, x: RIGHT_LINES_NUMBERS_CONTAINER_POSITIONS_X, y: 384 },
];

export const MESSAGE_BANNER_MOBILE_SCALE = 0.93;

// CHARACTER POSITION
export const CHARACTER_BOY_LANDSCAPE_POSITION_X = -185;
export const CHARACTER_BOY_LANDSCAPE_POSITION_Y = 520;
export const CHARACTER_GIRL_LANDSCAPE_POSITION_X = 1140;
export const CHARACTER_GIRL_LANDSCAPE_POSITION_Y = 630;

export const CHARACTER_BOY_PORTRAIT_POSITION_X = 240;
export const CHARACTER_BOY_PORTRAIT_POSITION_Y = -70;
export const CHARACTER_GIRL_PORTRAIT_POSITION_X = 730;
export const CHARACTER_GIRL_PORTRAIT_POSITION_Y = -190;

// BUY FEATURE BTN
export const FEATURE_BTN_LANDSCAPE_POSITION_X = -225;
export const FEATURE_BTN_LANDSCAPE_POSITION_Y = 497;
export const FEATURE_BTN_LANDSCAPE_WIDTH = 185;
export const FEATURE_BTN_LANDSCAPE_HEIGHT = 175;

export const FEATURE_BTN_PORTRAIT_POSITION_Y_MARGIN = 387.5;
export const FEATURE_BTN_PORTRAIT_WIDTH = 175;
export const FEATURE_BTN_PORTRAIT_HEIGHT = 175;

// BUY FEATURE POPUP
export const FEATURE_POPUP_WIDTH = 600;
export const FEATURE_POPUP_HEIGHT = 295;
export const FEATURE_POPUP_TEXT_ALPHA = 0.25;
export const FEATURE_POPUP_TITLE_POSITION_Y = 12;
export const FEATURE_POPUP_TITLE_POSITION_X = GAME_CONTAINER_WIDTH / 2;
export const FEATURE_POPUP_CANCEL_BTN_WIDTH = 282;
export const FEATURE_POPUP_CANCEL_BTN_HEIGHT = 109;
export const FEATURE_POPUP_CANCEL_BTN_POSITION_Y = 580;
export const FEATURE_POPUP_CANCEL_BTN_POSITION_X = GAME_CONTAINER_WIDTH / 2 - 200;
export const FEATURE_POPUP_OK_BTN_WIDTH = 282;
export const FEATURE_POPUP_OK_BTN_HEIGHT = 109;
export const FEATURE_POPUP_OK_BTN_POSITION_Y = FEATURE_POPUP_CANCEL_BTN_POSITION_Y;
export const FEATURE_POPUP_OK_BTN_POSITION_X = GAME_CONTAINER_WIDTH / 2 + 200;
export const FEATURE_POPUP_TOTAL_CONST_TEXT_POSITION_X = FEATURE_POPUP_CANCEL_BTN_POSITION_X;
export const FEATURE_POPUP_TOTAL_CONST_TEXT_POSITION_Y = 300;
export const FEATURE_POPUP_TOTAL_CONST_TEXT_AMOUNT_POSITION_X = FEATURE_POPUP_OK_BTN_POSITION_X;
export const FEATURE_POPUP_TOTAL_CONST_TEXT_AMOUNT_POSITION_Y = FEATURE_POPUP_TOTAL_CONST_TEXT_POSITION_Y;
export const FEATURE_POPUP_AMOUNT_TEXT_POSITION_X = FEATURE_POPUP_CANCEL_BTN_POSITION_X;
export const FEATURE_POPUP_AMOUNT_TEXT_POSITION_Y = 480;
export const FEATURE_POPUP_INPUT_WIDTH = 260;
export const FEATURE_POPUP_INPUT_HEIGHT = 80;
export const FEATURE_POPUP_INPUT_POSITION_Y = FEATURE_POPUP_AMOUNT_TEXT_POSITION_Y - 8;
export const FEATURE_POPUP_INPUT_POSITION_X = FEATURE_POPUP_OK_BTN_POSITION_X;
export const FEATURE_POPUP_MINUS_BTN_WIDTH = 80;
export const FEATURE_POPUP_MINUS_BTN_HEIGHT = FEATURE_POPUP_MINUS_BTN_WIDTH;
export const FEATURE_POPUP_MINUS_BTN_POSITION_Y = FEATURE_POPUP_AMOUNT_TEXT_POSITION_Y - 14;
export const FEATURE_POPUP_MINUS_BTN_POSITION_X =
  FEATURE_POPUP_OK_BTN_POSITION_X - FEATURE_POPUP_INPUT_WIDTH / 2 - FEATURE_POPUP_MINUS_BTN_WIDTH + 20;
export const FEATURE_POPUP_PLUS_BTN_WIDTH = FEATURE_POPUP_MINUS_BTN_WIDTH;
export const FEATURE_POPUP_PLUS_BTN_HEIGHT = FEATURE_POPUP_MINUS_BTN_WIDTH;
export const FEATURE_POPUP_PLUS_BTN_POSITION_Y = FEATURE_POPUP_MINUS_BTN_POSITION_Y;
export const FEATURE_POPUP_PLUS_BTN_POSITION_X =
  FEATURE_POPUP_OK_BTN_POSITION_X + FEATURE_POPUP_INPUT_WIDTH / 2 + FEATURE_POPUP_PLUS_BTN_WIDTH - 20;
export const FEATURE_POPUP_BET_VALUE_POSITION_Y = FEATURE_POPUP_AMOUNT_TEXT_POSITION_Y + 24;
export const FEATURE_POPUP_BET_VALUE_POSITION_X = FEATURE_POPUP_OK_BTN_POSITION_X;
export const FEATURE_POPUP_MIN = 1;
export const FEATURE_POPUP_MAX = 40;

export const FEATURE_POPUP_BASE_POS_X = 0;
export const FEATURE_POPUP_BASE_POS_Y = 75;
export const FEATURE_POPUP_POS_X = 15;
export const FEATURE_POPUP_POS_Y = -65;
export const FEATURE_POPUP_TITLE_POS_X = -10;
export const FEATURE_POPUP_TITLE_POS_Y = 640;

export const FEATURE_POPUP_BB_BTN_POS_X = GAME_CONTAINER_WIDTH / 2 - 262;
export const FEATURE_POPUP_BB_BTN_POS_Y = 113;
export const FEATURE_POPUP_HBB_BTN_POS_X = GAME_CONTAINER_WIDTH / 2 + 262;
export const FEATURE_POPUP_HBB_BTN_POS_Y = FEATURE_POPUP_BB_BTN_POS_Y;
export const FEATURE_POPUP_BB_TITLE_POS_X = FEATURE_POPUP_BB_BTN_POS_X;
export const FEATURE_POPUP_BB_TITLE_POS_Y = FEATURE_POPUP_BB_BTN_POS_Y + 50;
export const FEATURE_POPUP_HBB_TITLE_POS_X = FEATURE_POPUP_HBB_BTN_POS_X;
export const FEATURE_POPUP_HBB_TITLE_POS_Y = FEATURE_POPUP_BB_TITLE_POS_Y;
export const FEATURE_POPUP_MAX_LEVEL_TITLE_POS_X = FEATURE_POPUP_HBB_BTN_POS_X;
export const FEATURE_POPUP_MAX_LEVEL_TITLE_POS_Y = 287;
export const FEATURE_POPUP_BB_COST_POS_X = FEATURE_POPUP_BB_BTN_POS_X;
export const FEATURE_POPUP_BB_COST_POS_Y = FEATURE_POPUP_BB_BTN_POS_Y + 242 + 33;
export const FEATURE_POPUP_HBB_COST_POS_X = FEATURE_POPUP_HBB_BTN_POS_X;
export const FEATURE_POPUP_HBB_COST_POS_Y = FEATURE_POPUP_BB_COST_POS_Y;

// BUY FEATURE CONFIRM
export const FEATURE_POPUP_CONFIRM_WIDTH = 356;
export const FEATURE_POPUP_CONFIRM_HEIGHT = FEATURE_POPUP_HEIGHT;
export const FEATURE_POPUP_CONFIRM_POPUP_BG_WIDTH = 1173;
export const FEATURE_POPUP_CONFIRM_TITLE_POSITION_X = GAME_CONTAINER_WIDTH / 2;
export const FEATURE_POPUP_CONFIRM_TITLE_POSITION_Y = 220; //85;
export const FEATURE_POPUP_CONFIRM_FREE_SPINS_TITLE_POS_X = FEATURE_POPUP_CONFIRM_TITLE_POSITION_X;
export const FEATURE_POPUP_CONFIRM_FREE_SPINS_TITLE_POS_Y = 140;
export const FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POSITION_Y = 330;
export const FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POSITION_X = FEATURE_POPUP_CONFIRM_TITLE_POSITION_X;
export const FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POSITION_Y = FEATURE_POPUP_CONFIRM_TOTAL_COST_TEXT_POSITION_Y + 70;
export const FEATURE_POPUP_CONFIRM_TOTAL_COST_VALUE_POSITION_X = FEATURE_POPUP_CONFIRM_TITLE_POSITION_X;
export const FEATURE_POPUP_CONFIRM_CANCEL_BTN_WIDTH = 282;
export const FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_Y = 600;
export const FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_X = GAME_CONTAINER_WIDTH / 2 - 130;
export const FEATURE_POPUP_CONFIRM_OK_BTN_POSITION_Y = FEATURE_POPUP_CONFIRM_CANCEL_BTN_POSITION_Y;
export const FEATURE_POPUP_CONFIRM_OK_BTN_POSITION_X = GAME_CONTAINER_WIDTH / 2 + 130;
export const FEATURE_POPUP_CONFIRM_POSITION_X = FEATURE_POPUP_CONFIRM_TITLE_POSITION_X;
export const FEATURE_POPUP_CONFIRM_POSITION_Y = 250;
export const FEATURE_POPUP_CONFIRM_MAX_LEVEL_TITLE_POS_X = GAME_CONTAINER_WIDTH / 2;
export const FEATURE_POPUP_CONFIRM_MAX_LEVEL_TITLE_POS_Y = 250;

// ALBUM BTN
export const ALBUM_BTN_CONTAINER_POSITION_Y = 250;
export const ALBUM_BTN_CONTAINER_POSITION_X = -140;
export const ALBUM_BTN_LANDSCAPE_WIDTH = 175;
export const ALBUM_BTN_LANDSCAPE_HEIGHT = 600;

export const ALBUM_BTN_PORTRAIT_WIDTH = 175;
export const ALBUM_BTN_PORTRAIT_HEIGHT = 175;

// PHOENIX
export const PHOENIX_POX_X = -600;
export const PHOENIX_POX_Y = -200;

// SLOT_STOP_DISPLAY_SPRITE_COLOR
export const DEFAULT_SPRITE_COLOR = 0xffffff;
export const NOT_WIN_LINE_SPRITE_COLOR = 0x2f2f2f;

export const AFTER_SP_SYMBOL_FIX_DELAY = 1000;

//LATOMATINA
export const LATOMATINA_SMALL_SPEED = 500;
export const LATOMATINA_SMALL_MAX = 15;
export const LATOMATINA_SMALL_MARGIN = 75;

// SPIN_COUNTER
export const SPIN_COUNTER_LANDSCAPE_POSITION_X = SLOTS_CONTAINER_WIDTH + 125;
export const SPIN_COUNTER_LANDSCAPE_POSITION_Y = SLOTS_CONTAINER_HEIGHT - 52;
export const SPIN_COUNTER_PORTRAIT_POSITION_X = SLOTS_CONTAINER_WIDTH - 50 - 170;
export const SPIN_COUNTER_PORTRAIT_POSITION_Y = SLOTS_CONTAINER_HEIGHT + 125;

//THROW_TOMATO_DELAY_TIME
export const THROW_TOMATO_DELAY_TIME_LONG = 1000; //750;
export const THROW_TOMATO_DELAY_TIME_SHORT = 250;

export type Dict = {
  [key: string]: SlotId;
};

export type DictNumber = {
  [key: number]: SlotId;
};

type featureStartMessageType = Record<FeatureState, { title: string; detail: string }>;

export const featureStartMessage: featureStartMessageType = {
  [FeatureState.LA_TOMATINA]: { title: 'laTomatinaTitle', detail: 'laTomatinaText' },
  [FeatureState.TOMATO_SPIN]: { title: 'tomatoAndSpinTitle', detail: 'tomatoAndSpinText' },
};

export const MESSAGE_BANNER_LAYER = 3;

// INDICATOR
export const INDICATOR_MULTIPLIER_IN_SCALE_START = 1;
export const INDICATOR_MULTIPLIER_IN_SCALE_END = 1.8;
export const INDICATOR_MULTIPLIER_IN_DURATION = 500;

export const INDICATOR_MULTIPLIER_OUT_SCALE_START = INDICATOR_MULTIPLIER_IN_SCALE_END;
export const INDICATOR_MULTIPLIER_OUT_SCALE_END = INDICATOR_MULTIPLIER_IN_SCALE_START;
export const INDICATOR_MULTIPLIER_OUT_DURATION = INDICATOR_MULTIPLIER_IN_DURATION;

// 1spin 10 * 2 = 20credit
export const BASE_CREDIT = 20;
