import { BonusStatus, GameMode, UserBonus, bonusIds } from '../../global.d';
import {
  setBrokenBuyFeature,
  setBrokenGame,
  setCurrentBonus,
  setCurrentBonusId,
  setCurrentFreeSpinsTotalWin,
  setIsLaTomatinaFeature,
  setSlotConfig,
} from '../../gql/cache';
import client from '../../gql/client';
import { ISlotHistoryData } from '../../gql/d';
import { getUserBonuses, userBonusBetsGql } from '../../gql/query';
import { getGameModeByBonusId } from '../../utils';

export const checkBrokenGame = async () => {
  const activeUserBonusData = await client.query<{
    userBonuses: UserBonus[];
  }>({
    query: getUserBonuses,
    variables: {
      input: { status: BonusStatus.ACTIVE, slotId: setSlotConfig().id },
    },
    fetchPolicy: 'network-only',
  });

  if (activeUserBonusData.data.userBonuses.length > 0) {
    setBrokenGame(true);

    const gameMode = getGameModeByBonusId(activeUserBonusData.data.userBonuses[0]!.bonusId);
    if (gameMode === GameMode.FREE_SPIN_LA_TOMATINA) {
      setBrokenBuyFeature(true);
      if (activeUserBonusData.data.userBonuses[0]!.bonusId === bonusIds[GameMode.BUY_FEATURE_LA_TOMATINA]) {
        setIsLaTomatinaFeature(true);
      }
      setCurrentBonusId(activeUserBonusData.data.userBonuses[0]!.id);

      setCurrentBonus({
        ...activeUserBonusData.data.userBonuses[0]!,
        isActive: true,
        currentRound: 0,
        gameMode,
      });
    } else if (gameMode === GameMode.FREE_SPIN_TOMATO_SPIN_TOMATO || gameMode === GameMode.FREE_SPIN_TOMATO_SPIN_SPIN) {
      const freeSpinBonus = activeUserBonusData.data.userBonuses[0]!;
      setBrokenBuyFeature(true);
      setCurrentBonusId(freeSpinBonus.id);

      setCurrentBonus({
        ...freeSpinBonus,
        isActive: true,
        rounds: 3,
        currentRound: 0,
        gameMode,
      });

      const fsTotalAmount = freeSpinBonus.totalWinAmount ? freeSpinBonus.totalWinAmount / freeSpinBonus.coinValue : 0;
      setCurrentFreeSpinsTotalWin(fsTotalAmount);
    } else {
      setCurrentBonus({
        ...activeUserBonusData.data.userBonuses[0]!,
        isActive: true,
        currentRound: 0,
        totalRounds: 0,
        gameMode,
      });
      setCurrentFreeSpinsTotalWin(0);
      const userBonusBetsData = await client.query<{
        bets: ISlotHistoryData;
      }>({
        query: userBonusBetsGql,

        variables: {
          input: {
            filter: {
              userBonusId: setCurrentBonus().id,
            },
          },
        },
        fetchPolicy: 'network-only',
      });

      setCurrentBonus({
        ...setCurrentBonus(),
        currentRound: userBonusBetsData.data.bets.pageInfo.count,
        rounds: 3,
        totalWinAmount: 0,
      });
    }
  }
};
